.c-description-item {
  display: flex;
  color: #666666;
  align-items: center;
  
  &__icon {
    font-size: 0.16rem;
    margin-right: 0.16rem;
    align-items: center;
  }
}

@media screen
and (min-width: 300px)
and (max-width: 520px) {
  .c-description-item {
    &__icon {
      margin-right: 0.1rem;
      font-size: 0.12rem;
    }
  }

}

