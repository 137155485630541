@import '../../assets/styles/variable.less';
@import '../../assets/styles/extends.less';

body {
  background-color: @white;
}

.kz-layout {
  font-family: @font-one;
  padding-top: 84px;

  &__header.layout-header {
    height: 84px;
    padding: 0 5.9%;
    box-shadow:0px 3px 12px 1px rgba(0, 0, 0, 0.06);
  }

  &__left {
    &:extend(.v-center);
    
    & .intruction-entrance {
      height: 60px;
      display: flex;
      align-items: center;
      margin-left: 42px;

      &-img {
        height: 100%;
        width: auto;
      }
    }
  }

  &__nav.layout-header__links-nav {
    width: 5.8rem;

    & .link {
      display: flex;
      flex-direction: column;
      align-items: center;

      & a {
        &:hover {
          color: #198ED9;
          font-weight: bold;
        }
      }

      & .current-layout {
        color: #198ED9;
        font-weight: bold;
      }

      & .selected {
        height: 2px;
        width: 100%;
        background-color: #198ED9 !important; 
        margin-top: 4px;
      }
    } 
  }

  &__right:extend(.v-center) {
    justify-content: space-between;

    & .login-buttons {
      &:extend(.flex);

      & .login-btn,
      & .register-btn {
        width: auto;
        height: auto;
        border-width: 1px;
        font-size: 0.16rem;
      }
  
      & .login-btn {
        padding: 0.08rem 0.34rem;
        margin-right: 0.1rem;
      }
  
      & .register-btn {
        padding: 0.08rem 0.22rem;
      }
    }

    & .wap-menu {
      display: none;
    }

    & .user-info {
      display: flex;
      align-items: center;

      & .info-unit {
        margin-right: 16px;
        font-size: 0.14rem;
        color: #4c4c4c;

        &:nth-child(2),
        &:nth-child(3),
        &:last-child {
          &:hover {
            color: #198ED9;
          }
        }
        
        &:last-child {
          margin-right: 0;
          cursor: pointer;
        }
      }
    }
  }

  &__site-create-btn {
    display: none;
    visibility: hidden;
  }
}

@media screen 
and (min-width: 1024px) 
and (max-width: 1350px) {
  .kz-layout__header.layout-header {
    padding: 0 3%;
  }

  .kz-layout .login-buttons {
    & .login-btn {
      padding: 0.08rem 0.2rem;
    }

    & .register-btn {
      padding: 0.08rem 0.18rem;
    }
  }
}

@media screen 
and (min-width: 300px)
and (max-width: 1023px) {
  .kz-layout {
    padding: 50px 0;

    &__header.layout-header {
      height: 50px;
      padding: 12px;
      z-index: 1001;
    }

    &__nav.layout-header__links-nav {
      display: none;
    }

    &__left,
    &__right {
      height: 100%;
    }

    &__left {
      & .intruction-entrance {
        display: none;
      }
    }

    &__right {

      & .login-buttons {
        & .login-btn,
        & .register-btn {
          font-size: 14px;
          height: 34.2px;
        }

        & .login-btn {
          width: 82px;
          padding: 6px 0;
          margin-right: 7px;
        }

        & .register-btn {
          width: 92px;
          padding: 6px 0;
        }
      }

      & .user-info {
        display: none;
      }

      & .wap-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        margin-left: 12px;
        width: 28px;
        height: 28px;
        border: 2px solid #A4A4A4;
        padding: 6px;


        & .short-line,
        & .long-line {
          height: 2px;
          background-color: #A4A4A4;
        }

        & .short-line {
          width:70%;
        }
        
        & .long-line {
          width: 100%;
        }
      }
    }

    &__drawer {
      & .drawer-link {
        color: #000000;
        font-size: 16px;
        margin-bottom: 24px;
      }

      & .login-out-btn {
        position: absolute;
        bottom: 90px;
        height: 36px;
        font-size: 16px;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
  
        & .b-user-avatar {
          width: 65px;
          height: 65px;
          border-radius: 50%;
        }
      }
    }

    &__site-create-btn  {
      display: block;
      visibility: visible;
      text-align: center;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      background-color: #188ED8;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 999;
    }
  }
}







