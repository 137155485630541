@import '../../../../assets/styles/variable.less';
@import '../../../../assets/styles/extends.less';

.c-kkd-card {
  &:extend(.c-h-center);
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow:0px 2px 13px 0px rgba(16,36,213,0.1);
  border-radius: 10px;
  min-height: 336px;
  font-size: 0.16rem;
  overflow: hidden;

  &:hover {
    box-shadow:-2px 4px 13px 4px rgba(16,36,213,0.13);

    & .c-kkd-card__image {
      transform: scale(1.1);
    }
  }


  &__image {
    width: 100%;
  }

  &__content {
    &:extend(.c-h-center);
    width: 100%;
  }

  &__title {
    padding: 0 13%;
    font-weight: bold;
    font-size: 0.22rem;
    text-align: center;
    width: 100%;

    &-inner:extend(.c-h-center) {
      border-bottom: 1px solid rgba(235,235,235,1);
      padding: 0.32rem 0 0.25rem 0;
      width: 100%;
    }
  }

  &__descriptions {
    &:extend(.c-v-center);
    flex: 1;
    padding: 8% 12% 10%;

    & .kkd-desc {
      margin-bottom: 0.24rem;
      align-items: flex-start;
      line-height: 1.6;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .c-kkd-card {
    &__title {
      font-size: 0.2rem;
    }
  }
}

@media screen 
and (min-width: 521px) 
and (max-width: 1023px) {
  .c-kkd-card {
    margin-bottom: 2.6%;
    
    &__title {
      font-size: 0.4rem;
      padding: 0 4%;

      &-inner {
        flex-direction: row;
        justify-content: center;

        & p {
          &:first-child {
            margin-right: 20px;
          }

          &:last-child {  // 消除单条 title 的 margin
            margin-right: 0;
          }
        }
      }
    }

    &__descriptions {
      padding: 4% 10% 4%;

      & .kkd-desc {
        font-size: 0.28rem;

        & .c-description-item__icon {
          position: relative;
          margin-top: 0.05rem;
        }
      }
    }
  }
}

@media screen
and (min-width: 300px)
and (max-width: 520px) {
  .c-kkd-card {
    margin-bottom: 2.6%;

    &__title {
      padding: 0 4%;
      font-size: 0.24rem;

      &-inner {
        flex-direction: row;
        justify-content: center;

        & p {
          &:first-child {
            margin-right: 10px;
          }

          &:last-child {  // 消除单条 title 的 margin
            margin-right: 0;
          }
        }
      }
    }

    &__descriptions {
      padding: 4% 10% 4%;
      
      & .kkd-desc {
        font-size: 0.2rem;

        & .c-description-item__icon {
          position: relative;
          margin-top: 0.05rem;
        }
      }

      & .desc-icon {
        font-size: 0.14rem;
      }
    }
  }
}