@import '../../../../assets/styles/extends.less';

.c-service-menu:extend(.column) {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: 0.18rem;
  background: #fff;
  box-shadow: 0px 5px 12px 1px rgba(127,140,255,0.26);
  border-radius: 10px;

  &__title {
    width: 100%;
    padding: 0.12rem 0.31rem;
    font-size: 0.16rem;
    box-shadow:0px 4px 8px 0px rgba(0, 0, 0, 0.05);
  }

  &__links {
    padding: 8%;
    overflow-y: hidden;

    &-item:extend(.flex) {
      margin-bottom: 0.18rem;
      text-align: left;
      width: 100%;
      cursor: pointer;
      align-items: center;
      height: fit-content;

      &:hover {
        // transform: translateY(-8px);  // float
        transform: scale(1.1); // grow
      }

      & .link-img {
        width: 13%;
        object-fit: contain;
        margin-right: 8%;
        max-height: 100%;
      }

      & > .link-text {
        &:extend(.column);
        justify-content: space-between;
        height: 100%;

        & > .link-title {
          color: #00224D;
          font-size: 0.18rem;
          font-weight: bold;
        }

        & > .link-tip {
          font-size: 0.13rem;
          color: #565656;
        }
      }
    }
  }
}

@media screen 
and (min-width: 1024px) 
and (max-width: 1199px) {
  .c-service-menu {
    &__links {
      overflow-y: auto;
    }
  }
}

// wap
@media screen 
  and (min-width: 300px) 
  and (max-width: 1023px) {
    .c-service-menu {
      border-radius: 0;
      box-shadow: none;

      &__title {
        display: none;
      }

      &__links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8% 0 0;

        &-item {
          width: 30%;
          flex-direction: column;
          align-items: center;
          margin-bottom: 32px;
        }

        & .link-img {
          width: 32%;
          margin-bottom: 16px;
          margin-right: 0;
          object-fit: fill;
        }
        & .link-text {
          & > .link-title {
            font-size: 0.257rem;
          }

          & > .link-tip {
            display: none;
          }
        }
      }
    }
  }