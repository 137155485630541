@import '../../../../assets/styles/extends.less';
@import '../../../../assets/styles/mixin.less';

.c-update-item {
  display: flex;
  background: #ffffff;
  padding: 22px;
  min-height: 228px;
  width: 100%;
  border-bottom: 1px solid #EBEBEB;

  &__thumb {
    width: 250px;
    height: 100%;
    & .thumb-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  &__content {
    &:extend(.column);
    font-size: 14px;
    flex: 1;
    margin-left: 15px;
    height: 100%;

    & a {
      color: #1890ff;
    }

    &-title {
      font-size: 20px;
      color: #282828;
      font-weight: bold;
      margin-bottom: 5px;
    }

    &-public-time {
      color: #207CD3;
      margin-bottom: 5px;
    }

    &-description {
      color: #6F6F6F;
      line-height: 1.6;
      white-space: pre-wrap;
      .text-lines(5)
    }
  }
}