@import '../../../../assets/styles/extends.less';

.c-site-card:extend(.c-v-center) {
  width: 100%;
  background: #ffffff;;
  box-shadow:0px 3px 16px 1px rgba(127,140,255,0.15);  border-radius:10px;
  padding: 0.22rem 0.3rem;
  margin-bottom: 8px;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow:0px 5px 16px 1px rgba(127,140,255,0.39);
  }

  &__title {
    margin-bottom: 0.2rem;
    font-weight: bold;
    font-size: 0.22rem;
  }

  &__descriptions {
    &:extend(.flex-wrap);
    min-height: 0.56rem;
    align-items: flex-start;

    & .site-desc {
      min-width: 40%;
      margin-bottom: 0.1rem;
      margin-right: 0.15rem;
      font-size: 0.16rem;
      align-items: center;
    }
  }
}

@media screen 
and (min-width: 521px) 
and (max-width: 1023px) {
  .c-site-card:extend(.c-v-center) {
    margin-bottom: 0.4rem;

    &__title {
      font-size: 0.4rem;
    }

    &__descriptions {
      flex-direction: column;
      height: fit-content;

      & .site-desc {
        width: 100%;
        margin-bottom: 0.1rem;
        font-size: 0.26rem;
      }
    }
  }
}

@media screen
and (min-width: 300px)
and (max-width: 520px) {
  .c-site-card {
    &__descriptions {
      & .site-desc {
        font-size: 0.2rem;
      }

      & .desc-icon {
        font-size: 0.12rem;
      }
    }
  }
}