/**
 * 多行省略（容器可以不设置高度）
 *  webpack编译时，autoprefixer会将-webkit-box-orient属性省略，必须采用黑科技克服
 *
 * @lineNum 行数
 */
 .text-lines(@lineNum) {
  display: -webkit-box;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @lineNum;
  overflow: hidden;
  text-overflow: ellipsis;
}