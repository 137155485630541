@import '../../../../assets//styles/extends.less';

.c-official-account-card:extend(.v-center) {
  width: 100%;
  background: #ffffff;;
  box-shadow:0px 2px 13px 0px rgba(16,36,213,0.1);
  border-radius:10px;
  padding: 0.25rem;
  padding-right: 0;

  &:hover {
    box-shadow:-2px 4px 13px 4px rgba(16,36,213,0.13);
  }

  &__content {
    &:extend(.c-v-center);
  }

  &__image {
    width: 34.1%;
    margin-right: 5.6%;
  }

  &__title {
    margin-bottom: 0.25rem;
    font-weight: bold;
    font-size: 0.22rem;
  }

  &__descriptions {
    &:extend(.flex-wrap);
    flex: 1;
    height: 0.58rem;
    align-items: flex-start;

    & .offc-acc-desc {
      width: 50%;
      font-size: 0.16rem;

      &:first-child,
      &:nth-child(2) {
        margin-bottom: 0.1rem;
      }
    }
  }
}

@media screen 
and (min-width: 521px) 
and (max-width: 1023px) {
  .c-official-account-card {
    &__title {
      font-size: 0.4rem;
    }

    &__descriptions {
      & .offc-acc-desc {
        font-size: 0.28rem;
      }
    }
  }
}

@media screen
and (min-width: 300px)
and (max-width: 520px) {
  .c-official-account-card {
    &__image {
      width: 30%;
      margin-right: 3.6%;
    }

    &__descriptions {
      & .offc-acc-desc {
        font-size: 0.2rem;
      }

      & .desc-icon {
        font-size: 0.14rem;
      }
    }
  }
}