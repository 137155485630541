@import '../../../../assets/styles/extends.less';
@import '../../../../assets/styles/variable.less';

.c-users-panel {
  color: #222222;
  &:extend(.c-h-center);
  width: 100%;

  &__data:extend(.flex) {
    width: 100%;
    justify-content: space-around;

    & .data-item:extend(.c-h-center) {
      line-height: 1;

      & .data-title {
        font-size: 0.24rem;
        font-weight:bold;
      }

      & .data-number {
        margin-bottom: 0.4rem;
        
        & .emphasis-num {
          color: #207CD3;
          font-size: 0.85rem;
          font-family: 'PingFangSC-Medium';
        }

        & .plus-symbol {
          color: #207CD3;
          font-size: 0.6rem;
          font-weight:bold;
        }

        & .data-unit {
          font-size: 0.36rem;
        }
      }
    }
  }

  &__transitional-words:extend(.c-h-center) {
    & .emphasis-text {
      font-size: 0.38rem;
      margin-bottom: 0.4rem;
      margin-top: 0.86rem;
      font-weight: bold;
    }

    & .shallow-text {
      color: #444444;
      font-size: 0.24rem;
      line-height: 1.8;
    }
  }

  &--ul {
    margin-top: 70px;
    position: relative;
    width: 100%;
  }

  &--li {
      display: inline-block;
      font-size: 0;
      margin-bottom: 16px;
      text-align: center;
      width: 14%;
  }

  &--logo {
      height: 67px;
      width: 140px;

      &:hover {
          box-shadow: 0 0 10px rgba(211, 217, 241, 0.5);
      }
  }
}

@media screen 
  and (min-width: 300px) 
  and (max-width: 1023px) {
    .c-users-panel {
      &__transitional-words,
      &--ul {
        display: none;
      }

      &__data {
        width: 100%;
    
        & .data-item:extend(.c-h-center) {
          line-height: 1;
    
          & .data-title {
            font-size: 0.2rem;
            font-weight:bold;
          }
    
          & .data-number {
            margin-bottom: 0.2rem;
            
            & .emphasis-num {
              color: #207CD3;
              font-size: 0.45rem;
              font-family: 'PingFangSC-Medium';
            }
    
            & .plus-symbol {
              color: #207CD3;
              font-size: 0.31rem;
              font-weight:bold;
            }
    
            & .data-unit {
              font-size: 0.24rem;
            }
          }
        }
      }
    }
  }