@import '../../../../assets//styles/extends.less';

.c-marketing-card:extend(.flex) {
  align-items: flex-end;
  width: 100%;
  height: 100%;
  background: #ffffff;;
  box-shadow:0px 6px 14px 3px rgba(16,36,213,0.08);
  border-radius:10px;
  padding: 0 0 0.4rem 0.36rem;

  &:hover {
    box-shadow:-11px 2px 31px 1px rgba(1,18,164,0.1);
  }

  &__text-content:extend(.column) {
    flex: 1;
    height: 100%;

    & .marketing-btn {
      margin-top: 0.42rem;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 0.3rem;
    border-bottom: 1px solid rgba(235,235,235,1);
    padding: 0.3rem 0;
  }

  &__descriptions {
    &:extend(.column);
    padding-top: 0.3rem;
    // height: 60.7%;

    & .marketing-desc {
      min-width: 40%;
      margin-bottom: 3%;
      // height: 0.27rem;
      font-size: 0.16rem;
      align-items: flex-start;

      &:last-child{
        margin-bottom: 0;
      }

      & .c-circle-icon {
        position: relative;
        margin-top: 0.06rem;
      }
    }
  }
}

@media screen and (min-width: 1630px) {
  .c-marketing-card {
    padding-bottom: 0.6rem;

    &__title {
      padding-top: 0.6rem;
    }

    &__descriptions {
      & .marketing-desc {
        margin-bottom: 5%;
      }
    }
  }
}



@media screen 
and (min-width: 1026px) 
and (max-width: 1440px) {
  .c-marketing-card {
    &__title {
      font-size: 0.28rem;
    }

    &__descriptions {
      & .marketing-desc {
        font-size: 0.16rem;

        & .c-circle-icon {
          margin-top: 0.08rem;
        }
      }
    }
  }
}

@media screen
and (min-width: 300px)
and (max-width: 1024px) {
  .c-marketing-card {

    &__img-content {
      width: 48%;
      right: 10%;

      & .image-background {
        width: 2.7rem;
        height: 2.7rem;
      }

      & .topic-image {
        width: 115%;
      }
    }

    &__text-content {
      & .marketing-btn {
        width: 120px;
        height: 36px;
      }
    }

    &__descriptions {
      & .marketing-desc {
        font-size: 0.2rem;
        margin-bottom: 7%;
        height: auto;
      }

      & .desc-icon {
        font-size: 0.12rem;
      }
    }

    
  }
}