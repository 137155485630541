@import '../../../../assets/styles/extends.less';
@import '../../../../assets/styles/variable.less';

.c-our-infomation:extend(.h-v-center) {
  width: 100%;
  background-color: #383838;
  padding: 0.58rem 0 0.92rem 0; 
  font-family: @font-two;
  font-size: 0.16rem;

  &-container:extend(.r-space-between) {
    width: 90%;
  }

  & .qrcode-container:extend(.h-v-center) {
    width: 84px;
    height: 84px;
    background: @white;
    border-radius: 6px;

    & img {
      width: 97%;
      height: auto;
      border-radius: 6px;
    }
  }

  &__col {
    &:extend(.column);
    margin-top: 14px;
    padding-left: 8px;

    & .info-item {
      color: #B3B3B3;
    }

    & a.info-item {
      &:hover {
        color: #ffffff;
      }
    }

    & .free-trial {
      display: flex;
      align-items: stretch;

      &__input {
        width: 180px;
        height: 49px;
        background: #282828;
        border: 1px solid #198ED9;
        box-shadow: 3px 2px 6px 0px rgba(0, 0, 0, 0.16);
        padding: 16px 14px;
        font-size: 17px;
        color: #A1A1A1;
        outline: none;
      }

      &__button {
        width: 80px;
        height: 49px;
        background: #198ED9;
        color: #ffffff;
        font-size: 17px;
        border: 1px solid #198ED9;
        outline: none;
        cursor: pointer;
      }
    }
  }

  & .free-trial-prompt {
    &__children:extend(.c-h-center) {
      font-size: 15px;
      color: #323233;

      & .qrcode-container {
        width: 88px;
        height: 88px;
      }
    }

    & .kz-ui-modal__header .close-symbol {
      right: 26px;
      color: #323233;
    }
  }
}

@media screen 
  and (min-width: 1200px) 
  and (max-width: 1450px) {
  .c-our-infomation {
    &-container {
      width: 90%;
    }
  }
}

@media screen 
and (min-width: 1024px) 
and (max-width: 1199px) {
  .c-our-infomation {
    &-container {
      width: 97%;

      & .calling-card {
        &__cut {
          width: 240px;
        }
      }
    }
  }
}

@media screen 
  and (min-width: 320px) 
  and (max-width: 1023px) {
    .c-our-infomation {
      display: none;
    }
  }