@import '../../assets/styles/variable.less';
@import '../../assets/styles/extends.less';

.p-kz-homepage {
  font-size: 0.16rem;
  height: calc(100vh - 84px);
  overflow-y: scroll;
  
  &__screen {
    width: 100%;
    display: flex;

    & .opt-btn {
      margin-top: 3.5%;
      text-align: center;

      & .c-button {
        &:first-child {
          margin-right: 0.35rem;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-one {
      // background: #F9F9F9;
      padding: 2.5% 0 4.8% 0;
      
      & > .contents {
        width: 100%;
        display: flex;
        justify-content: center;

        & > .service-menu {
          width: 2.51rem;
          height: 4.68rem;
          margin-right: 0.3rem;
          
          @media screen 
            and (min-width: 1024px) 
            and (max-width: 1450px) {
              // width: 21%;
            }  
        }

        & > .carousel {
          // flex: 1;
          width: 10rem;
          height: 4.68rem;
          background: #fff;
          border-radius: 10px;
          box-shadow:0px 5px 12px 1px rgba(127,140,255,0.26);
          overflow: hidden;
          font-size: 0;
        }
      }
    }

    &-two {
      & .site-panel-children {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8.9%;
      
        & .site-panel-image {
          width: 55%;
          height: auto;
          object-fit: contain;
          position: relative;
          margin-right: -32px;
          animation-duration: 1.2s;
          overflow: hidden;
        }

        & .site-cards {
          width: 49%;
          // z-index: 1;
        }
      }
    }

    &-three {
      & .official-account-panel-children:extend(.c-h-center) {
        width: 100%;

        & .description-cards {
          display: flex;
          width: 100%;
          padding: 0 8.9%;
          flex-wrap: wrap;
          justify-content: center;
        }

        & .description-card {
          width: 48.7%;

          &:first-child,
          &:nth-child(2) {
            margin-bottom: 2.6%;
          }
          
          &:nth-child(odd) {
            margin-right: 2.6%;
          }
        }
      }
    }

    &-four {
      & .kkd-panel-children:extend(.c-h-center)  {
        padding: 0 8.9%;
        width: 100%;

        & .kkd-cards {
          &:extend(.h-center);
          width: 100%;
        }

        & .kkd-card {
          margin-right: 1.7%;
          width: 23.6%;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    &-five {
      & .marketing-panel-children:extend(.c-h-center) {
        width: 100%;
        padding: 0 8.9%;

        & .marketing-panel-item {
          &:extend(.h-center);
          width: 100%;
          margin-bottom: 2.6%;
          align-items: center;

          & .marketing-item-card {
            position: relative;
            z-index: 300;
            flex: 1;

            & .c-marketing-card {
              padding-right: 0.36rem;

              & .marketing-desc {
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }

          & .marketing-item-image {
            width: 54.33%;
            margin-left: -2%;

            @media screen and (min-width: 1630px) {
              width: 50%;
              margin-left: -1.5%;
            }
          }


          &:nth-child(even) {
            flex-direction: row-reverse;

            & .marketing-item-image {
              margin-left: 0;
              margin-right: -2%;
            }
          }
        }
      }
    }

    &-six {
      width: 100%;
    }
  }

  & .notices-modal {
    &__content {
      &:extend(.column);
      position: relative;
      height: 100%;
      width: 100%;
    }

    &__tip {
      height: 48px;
      box-shadow:0px 3px 16px 2px rgba(0, 0, 0, 0.07);
      color: #6F6F6F;
      font-size: 14px;
      text-align: center;
      vertical-align: middle;
      line-height: 48px;
      position: absolute;
      background: #ffffff;
      width: 100%;
      top: 0;
    }
  }
}

@media screen 
and (min-width: 1024px) 
and (max-width: 1199px) {
  .p-kz-homepage {
    &__screen {
      &-three {
        & .official-account-panel-children {
          & .description-cards  {
            padding: 0 6%;
          }
        }
      }

      &-two {
        & .site-panel-children {
          padding: 0 6%;
        }
      }

      &-four {
        & .kkd-panel-children {
          padding: 0 6%;
        }
      }

      &-five {
        & .marketing-panel-children {
          padding: 0 6%;
        }
      }
    }
  }
}

// 比 ipad Pro 小的屏宽
@media screen
and (min-width: 300px)
and (max-width: 1023px) {
  .p-kz-homepage {
    height: calc(100vh - 99px);
    &__screen {
      &-one {
        padding: 0;
        & > .contents {
          flex-direction: column;
          margin: 0;
          & > .service-menu {
            width: 100%;
            height: auto;
            order: 2;
          }

          & > .carousel {
            width: 100%;
            height: auto;
            border-radius: 0;
            z-index: 1;
          }
        }
      }

      &-two {
        & .site-panel-children {
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          padding: 0;

          & .site-panel-image {
            width: 100%;
            margin-bottom: 0.5rem;
            margin-right: 0;
          }

          & .site-cards {
            width: 100%;
            padding: 0 3%;
          }
        }
      }

      &-three {
        & .official-account-panel-children {
          & .description-cards  {
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: nowrap;
            padding: 0 3%;
          }

          & .description-card {
            width:100%;

            &:nth-child(3),
            &:last-child {
              margin-bottom: 2.6%;
            }

            &:nth-child(odd) {
              margin-right: 0;
            }
          }
        }
      }

      &-four {
        & .kkd-panel-children {
          padding: 0 3%;

          & .kkd-cards {
            flex-direction: column;
          }

          & .kkd-card {
            width: 100%;
          }
        }
      }

      &-five {
        & .marketing-panel-children {
          padding: 0;

          & .marketing-panel-item {
            width: 100%;
            flex-direction: column-reverse;
            margin-bottom: 3%;

            & .marketing-item-image {
              width: 100%;
              margin: 0;
              padding: 0;
              margin-bottom: 4%;
            }

            & .marketing-item-card {
              width: 100%;
              padding: 0 3%;
              z-index: initial;
            }

            &:nth-child(even) {
              width: 100%;
              flex-direction: column-reverse;
              margin-bottom: 3%;

              & .marketing-item-image {
                width: 100%;
                margin: 0;
                padding: 0;
                margin-bottom: 4%;
              }
            }
          }


          // & .marketing-panel-bottom {
          //   z-index: 1;
          //   padding: 0 3%;
          //   width: 100%;
          //   flex-direction: column;

          //   & .marketing-card {
          //     width: 100%;
          //     margin-bottom: 3%;
          //   }
          // }
        }
      }
    }
  }
}

// ipad 及以下
@media screen
  and (min-width: 300px)
  and (max-width: 769px) {
  }