@import '../../assets/styles/extends.less';

.c-panel-container:extend(.c-h-center) {
  padding: 0.47rem 0 0.4rem 0;
  width: 100%;

  &.initial-invisible {
    opacity: 0;
  }

  &__title {
    font-size: 0.4rem;
    color: #222222;
    font-weight: bold;
    margin-bottom: 0.56rem;
  }
}

@media screen
and (min-width: 300px)
and (max-width: 520px) {
  .c-panel-container {
    &__title {
      font-size: 0.3rem;
      margin-bottom: 0.4rem;
    }
  }
}